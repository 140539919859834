import { useContext } from 'react';
import { FormContext } from '../contexts/FormContext';
import usePost from './usePost';
import { ModalContext } from '../contexts/ModalContext';

export const useForms = (formId, validationSchema, fieldMap, url, method) => {
	const { forms, setForms } = useContext(FormContext);
	const { post, reqLoading, customErrors, setCustomErrors } = usePost();
	const { flashFadeModal } = useContext(ModalContext);

	const validate = async (formData) => {
		try {
			await validationSchema.validate(formData);
			return { valid: true };
		} catch (error) {
			return { valid: false, error: error.message };
		}
	};

	const updateForm = (formData) => {
		setCustomErrors({});
		setForms({ ...forms, [formId]: formData });
	};

	const submitForm = async (extraValidationCheck, successMessage, callback, defaultObj) => {
		let formData = forms[formId];
		const validation = await validate(formData);

		if (validation?.valid) {
			let extraValidation = { valid: true };
			extraValidationCheck ? (extraValidation = await extraValidationCheck(formData)) : null;

			if (extraValidation?.valid) {
				try {
					if (fieldMap) {
						formData = fieldMap(formData);
					}

					let res = await post({ ...formData }, url, null, method, true);
					console.log(res);
					if (res?.data) {
						flashFadeModal(successMessage);
						setForms({ ...forms, [formId]: defaultObj || {} });
						callback ? callback(res) : null;
						return true;
					} else {
						flashFadeModal(`There was an error with the request`);
						callback ? callback(res) : null;
						return false;
					}
				} catch (err) {
					console.log(err);
				}
			} else {
				flashFadeModal(extraValidation.message);
				return false;
			}
		} else {
			if (validation?.error) {
				const regex = /\{\{([^{}]+)\}\}\{\{\{([^{}]+)\}\}\}/;

				const match = validation?.error?.match(regex);
				if (match) {
					let errorField = match[1];
					let errorMessage = match[2];

					setCustomErrors({ [errorField]: errorMessage });
				}
			}
			return validation;
		}
	};

	return { formData: forms[formId], updateForm, submitForm, reqLoading, customErrors };
};
