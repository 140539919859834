import React from 'react';
import Toggle from '../Toggle';
import RadioInput from '../Inputs/RadioInput';
import CheckboxInput from '../Inputs/CheckboxInput';
import Button from '../Button';

export default function NotificationSettings({ stepWidth, updateSetting, resetSettingDefaults, formData }) {
	return (
		<div className='notificationSettings' style={{ width: `${stepWidth}px` }}>
			<h3>Notification settings</h3>
			<Button text='Reset defaults' size='sm' style='secondary' onClick={resetSettingDefaults} />
			<h4 className='subHeader'>Notify me about:</h4>
			<h4 className='categoryHeader'>PUSH NOTIFICATIONS</h4>
			<Toggle
				name='sendPushNotifications'
				onClick={updateSetting}
				value={formData?.sendPushNotifications}
				label='Send push notifications'
			/>
			<h4 className='categoryHeader'>MESSAGES</h4>
			<Toggle
				name='notifyNewMessages'
				onClick={updateSetting}
				value={formData?.notifyNewMessages}
				label='New messages'
			/>
			{formData?.notifyNewMessages ? (
				<>
					<CheckboxInput
						text={'Behalf of me'}
						name={'notifyBehalfOfOrderMessages'}
						checked={formData?.notifyBehalfOfOrderMessages}
						callback={(value, name) => {
							updateSetting(name, value);
						}}
						tooltip={
							'If checked, you will receive notifications for messages in orders that someone else has placed on behalf of you'
						}
						thin
					/>
				</>
			) : (
				<></>
			)}
			<h4 className='categoryHeader'>ORDERS PLACED</h4>
			<Toggle
				name='notifyOrderHolds'
				onClick={updateSetting}
				value={formData?.notifyOrderHolds}
				label='Order holds'
			/>
			<Toggle
				name='notifyOrderUpdates'
				onClick={updateSetting}
				value={formData?.notifyOrderUpdates}
				label='Order updates'
			/>
			<Toggle
				name='notifyOrderStatusChange'
				onClick={updateSetting}
				value={formData?.notifyOrderStatusChange}
				label='Order status change'
			/>
			{formData?.notifyOrderHolds || formData?.notifyOrderUpdates || formData?.notifyOrderStatusChange ? (
				<>
					<CheckboxInput
						text={'My orders'}
						name={'notifyMyPurchaseOrders'}
						checked={formData?.notifyMyPurchaseOrders}
						callback={(value, name) => {
							updateSetting(name, value);
						}}
						tooltip={'If checked, you will receive notifications for orders that you have placed'}
						thin
					/>
					<CheckboxInput
						text={'Behalf of me'}
						name={'notifyBehalfOfOrders'}
						checked={formData?.notifyBehalfOfOrders}
						callback={(value, name) => {
							updateSetting(name, value);
						}}
						tooltip={
							'If checked, you will receive notifications for orders that someone else has placed on behalf of you'
						}
						thin
					/>{' '}
				</>
			) : (
				<></>
			)}
			<h4 className='categoryHeader'>ORDERS RECEIVED</h4>
			<Toggle
				name='notifyNewOrders'
				onClick={updateSetting}
				value={formData?.notifyNewOrders}
				label='New orders'
			/>
			<Toggle
				name='notifyOrderUpdatesSeller'
				onClick={updateSetting}
				value={formData?.notifyOrderUpdatesSeller}
				label='Order updates'
			/>
			{formData?.notifyOrderUpdatesSeller ? (
				<>
					<CheckboxInput
						text={'All orders'}
						name={'notifyAllOrderSales'}
						checked={formData?.notifyAllOrderSales}
						callback={(value, name) => {
							updateSetting(name, value);
						}}
						tooltip={
							'If checked, you will receive notifications for all orders that are updated. If unchecked you will only receive notifications for orders that are assigned to you'
						}
						thin
					/>{' '}
				</>
			) : (
				<></>
			)}
			<h4 className='categoryHeader'>PRICING</h4>
			<Toggle
				name='notifyPricingUpdates'
				onClick={updateSetting}
				value={formData?.notifyPricingUpdates}
				label='Pricing updates'
			/>
			<Toggle
				name='notifyQuotationUpdates'
				onClick={updateSetting}
				value={formData?.notifyQuotationUpdates}
				label='Quotation updates'
			/>
			<h4 className='categoryHeader'>PARCELS</h4>
			<Toggle
				name='notifyParcelsDispatched'
				onClick={updateSetting}
				value={formData?.notifyParcelsDispatched}
				label='Parcels dispatched'
				disabled
			/>
			<Toggle
				name='notifyParcelsReceived'
				onClick={updateSetting}
				value={formData?.notifyParcelsReceived}
				label='Parcels received'
				disabled
			/>
			<Toggle
				name='notifyParcelTrackingStatusChange'
				onClick={updateSetting}
				value={formData?.notifyParcelTrackingStatusChange}
				label='Parcel tracking status change'
				disabled
			/>
			<h4 className='categoryHeader'>TEAM</h4>
			<Toggle
				name='notifyNewTeamMember'
				onClick={updateSetting}
				value={formData?.notifyNewTeamMember}
				label='New Team members'
			/>
			<Toggle
				name='notifyTrustedRequest'
				onClick={updateSetting}
				value={formData?.notifyTrustedRequest}
				label='Request to add a Trusted Supplier'
			/>
			<h4 className='categoryHeader'>GENERAL</h4>
			<Toggle
				name='mobileSettings'
				onClick={updateSetting}
				value={formData?.mobileSettings}
				label='Use different settings for mobile phone'
				disabled
			/>
			<Toggle
				name='notifyViaEmail'
				onClick={updateSetting}
				value={formData?.notifyViaEmail}
				label='Notify via email when offline'
			/>
			{/* <h4 className='categoryHeader'>NOTIFICATION SCHEDULE</h4> */}
		</div>
	);
}
