import Axios from 'axios';
import React, { useContext, useEffect } from 'react';
import Loading from '../components/Loading/Loading';
import config from '../config';
import { splitUserId } from '../helpers';
import { CurrentUserContext } from './CurrentUserContext';
import { useNavigate } from 'react-router';

let CurrentTeamContext = React.createContext();

function CurrentTeamContextProvider(props) {
	let initialState = {
		loading: true,
		reload: false,
		currentTeam: null,
		isTeamOfOne: false,
		userPermissions: {},
	};

	let { userState, userDispatch } = useContext(CurrentUserContext);

	let navigate = useNavigate();

	const reducer = (teamState, action) => {
		switch (action.type) {
			case 'RESET':
				localStorage.removeItem('currentTeam');
				return {
					...teamState,
					loading: true,
					reload: true,
					currentTeam: null,
					isTeamOfOne: false,
				};
			case 'SET_TEAM_DATA':
				return {
					...teamState,
					loading: false,
					reload: true,
					currentTeam: { ...teamState.currentTeam, ...action.payload },
				};
			case 'SET_NO_LOGIN':
				return {
					...teamState,
					loading: false,
					reload: false,
					currentTeam: {},
				};
			case 'SET_TEAM':
				localStorage.setItem('currentTeam', action.payload.teamUuid);
				if (action.payload.reload) {
					if (window.location.href.includes('team-registration')) {
						navigate('/manage');
					} else if (window.location.href.includes('manage') || window.location.href.includes('order')) {
						navigate('/');
					} else {
						window.location.reload();
					}
				} else if (action.payload.hardReload) {
					window.location.reload();
				}
				return {
					...teamState,
					loading: false,
					reload: false,
					userPermissions: extractUserPermissions(teamState.userTeams, action.payload.teamUuid),
				};
			case 'SET_TEAM_ADDRESSES':
				let currentTeam = { ...teamState.currentTeam };
				currentTeam.teamInfo.addresses = action.payload;
				return {
					...teamState,
					currentTeam: currentTeam,
					loading: false,
					reload: true,
				};
			case 'SET_USER_TEAMS':
				return {
					...teamState,
					loading: false,
					reload: false,
					userTeams: action.payload,
				};
			case 'FETCH_SUCCESS':
				let { permissions, role } = extractUserPermissionsAndRole(
					action.payload.userTeams,
					action.payload.currentTeam?.teamUuid
				);
				return {
					...teamState,
					loading: false,
					reload: false,
					currentTeam: action.payload.currentTeam,
					userTeams: action.payload.userTeams,
					isTeamOfOne: action.payload.isTeamOfOne,
					teamRole: role,
					userPermissions: permissions,
				};
			case 'RELOAD':
				return {
					...teamState,
					loading: true,
					reload: true,
				};
			case 'RE-FETCH_TEAM':
				getCurrentTeam();
				return {
					...teamState,
				};
			default:
				return {
					...teamState,
				};
		}
	};

	let [teamState, teamDispatch] = React.useReducer(reducer, initialState);
	let value = { teamState, teamDispatch };

	function extractUserPermissionsAndRole(userTeams, teamUuid) {
		if (!userTeams || !teamUuid) {
			return {};
		}
		let permissions = {};
		let role = '';
		let team = userTeams.filter((team) => team?.teamUuid === teamUuid)[0];
		if (team) {
			let member = team.members.filter((member) => member.userUuid === splitUserId(userState.currUser.sub))[0];
			if (member) {
				permissions = member.permissions || {};
				role = member.role || '';
			}
		}
		return { permissions, role };
	}

	async function convAvatarToURL(avatar) {
		if (avatar?.path) {
			try {
				let res = await Axios({
					url: `${config.auth}/file?path=${avatar.path}`,
					method: 'GET',
					responseType: 'blob',
				});
				if (res.status === 200) {
					avatar.url = window.URL.createObjectURL(new Blob([res.data]));
					return avatar;
				}
			} catch (err) {
				console.log(err);
				return avatar;
			}
		} else {
			return avatar;
		}
	}

	const getTeamMembersInfo = async (members) => {
		const userUuids = [];

		if (!members || members?.length === 0) {
			return;
		}
		for (let i = 0; i < members.length; i++) {
			userUuids.push(`"${members[i].userUuid}"`);
		}
		let res = await Axios({
			url: `${config.auth}/v1/user/users.read/{"users": [${userUuids}]}`,
			method: 'GET',
		});
		if (!res?.data?.users) {
			return;
		}
		for (let i = 0; i < members?.length; i++) {
			for (let i2 = 0; i2 < res.data.users.length; i2++) {
				if (members[i].userUuid === res.data.users[i2]?.userUuid && res.data.ok) {
					members[i].email = res.data.users[i2]?.email;
					members[i].firstname = res.data.users[i2]?.firstname;
					members[i].lastname = res.data.users[i2]?.lastname;
					members[i].name = res.data.users[i2]?.name;
					members[i].avatar = await convAvatarToURL(res.data.users[i2]?.avatar);
					members[i].phone = res.data.users[i2]?.phone;
					members[i].slack = res.data.users[i2]?.slack;
					members[i].skype = res.data.users[i2]?.skype;
				}
			}
		}
	};

	function getCurrentTeam() {
		try {
			Axios({
				url: `${config.apiv1}/team/team.read/me/${splitUserId(userState?.currUser?.sub)}`,
				method: 'GET',
			})
				.then((response) => {
					if (!response?.data?.data) {
						userDispatch({ type: 'LOGOUT' });
					}
					if (!localStorage.getItem('currentTeam')) {
						if (response.data.data[0]?.trustedBy?.length > 0) {
							for (let i = 0; i < response.data.data[0]?.trustedBy?.length; i++) {
								getTeamMembersInfo(response.data.data[0].trustedBy[i]?.members);
							}
							response.data.data[0].trustedBy.unshift({
								teamUuid: response.data.data[0].teamUuid,
								members: response.data.data[0].members,
								teamName: response.data.data[0].teamInfo.teamName,
							});
							response.data.data[0].trustedBy = response.data.data[0].trustedBy.reduce(
								(arr, value) => ({ ...arr, [value.teamUuid]: value }),
								{}
							);
						}
						localStorage.setItem('currentTeam', response?.data?.data[0]?.teamUuid);
						Axios.defaults.headers.common['teamUuid'] = response.data.data[0]?.teamUuid;
						getTeamMembersInfo(response.data.data[0]?.members).then(() => {
							teamDispatch({
								type: 'FETCH_SUCCESS',
								payload: {
									currentTeam: response.data.data[0],
									userTeams: response.data.data,
									isTeamOfOne:
										response.data.data[0]?.teamInitials === 'ME' ||
										response.data.data[0]?.isTeamOfOne === true,
								},
							});
						});
					} else {
						let currentTeam;
						let currentTeamCookie = localStorage.getItem('currentTeam');
						for (let team of response.data.data) {
							if (team.teamUuid === currentTeamCookie) {
								if (team?.trustedBy?.length > 0) {
									for (let i = 0; i < team?.trustedBy?.length; i++) {
										getTeamMembersInfo(team.trustedBy[i]?.members);
									}
									team.trustedBy.unshift({
										teamUuid: team.teamUuid,
										members: team.members,
										teamName: team.teamInfo.teamName,
									});
									team.trustedBy = team.trustedBy.reduce(
										(arr, value) => ({ ...arr, [value.teamUuid]: value }),
										{}
									);
								}
								Axios.defaults.headers.common['teamUuid'] = team.teamUuid;
								currentTeam = team;
							}
						}
						if (currentTeam) {
							getTeamMembersInfo(currentTeam.members).then(() => {
								teamDispatch({
									type: 'FETCH_SUCCESS',
									payload: {
										currentTeam: currentTeam,
										userTeams: response.data.data,
										isTeamOfOne:
											currentTeam?.teamInitials === 'ME' || currentTeam?.isTeamOfOne === true,
									},
								});
							});
						} else {
							teamDispatch({ type: 'RESET' });
						}
					}
				})
				.catch((err) => {
					if (err) {
						teamDispatch({ type: 'RESET' });
						return err;
					}
				});
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		if (userState && userState.isAuthenticated && !userState.loading && userState.currUser) {
			getCurrentTeam();
		} else {
			teamDispatch({ type: 'SET_NO_LOGIN' });
		}
	}, [userState?.isAuthenticated, teamState?.reload, userState?.reload, userState?.loading, userState.currUser]); // eslint-disable-next-line

	if (teamState?.loading) {
		return <Loading type='circle' />;
	}

	return <CurrentTeamContext.Provider value={value}>{props.children}</CurrentTeamContext.Provider>;
}

let CurrentTeamContextConsumer = CurrentTeamContext.Consumer;

export { CurrentTeamContext, CurrentTeamContextProvider, CurrentTeamContextConsumer };
