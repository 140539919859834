import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import ManageUserForm from '../../components/Forms/ManageUserForm';
import config from '../../config';
import Button from '../../components/Button';
import { useNavigate } from 'react-router';
import { splitUserId } from '../../helpers';
import useFetch from '../../hooks/useFetch';
import ChangePassForm from '../../components/Forms/ChangePassForm';

export default function UserManage() {
	const { userState } = useContext(CurrentUserContext);

	let navigate = useNavigate();

	const [user, setUser] = useState();

	const { fetch } = useFetch();

	useEffect(async () => {
		let res = await fetch(`${config.auth}/v1/user/user.read/${splitUserId(userState?.currUser?.sub)}`, null, true);
		setUser(res.user);
	}, []);

	return (
		<div className='pageInnerCont white'>
			<div className='appManageCont'>
				<div className='mainHeader'>
					<h1>Manage account</h1>
					<h2 className='userName'>{user?.name}</h2>
				</div>
				<ChangePassForm />
				<ManageUserForm user={user} setUser={setUser} />
				<div className='btnCont'>
					<Button
						text='Delete account'
						style='destructive'
						size='md'
						onClick={() => {
							navigate('/manage/user/delete-account');
						}}
					/>
				</div>
			</div>
		</div>
	);
}
