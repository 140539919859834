import React, { useContext, useEffect, useState } from 'react';
import TextInput from '../Inputs/TextInput';
import SelectInput from '../Inputs/SelectInput';
import prefixOptions from '../Options/prefixOptions';
import * as yup from 'yup';
import { useForms } from '../../hooks/useForms';
import config from '../../config';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { checkFileType, checkUploadFileTypeAllowed, splitUserId } from '../../helpers';
import Button from '../Button';
import FileInput from '../Inputs/FileInput';
import Axios from 'axios';
import { ModalContext } from '../../contexts/ModalContext';

export default function ManageUserForm({ user, setUser }) {
	const { userState, userDispatch } = useContext(CurrentUserContext);

	const [avatarSrc, setAvatarSrc] = useState();

	const { flashFadeModal } = useContext(ModalContext);

	const formValidationSchema = yup.object().shape({
		avatar: yup.mixed(),
		firstname: yup.string(),
		lastname: yup.string(),
		prefix: yup.string(),
		credentials: yup.string(),
		sector: yup.string(),
		profession: yup.string(),
		gdc: yup.string(),
		phone: yup.string(),
		skype: yup.string(),
		slack: yup.string(),
		whatsapp: yup.string(),
		telegram: yup.string(),
		contactEmail: yup.string(),
		threema: yup.string(),
		signal: yup.string(),
	});

	let userFieldMap = (formData) => {
		return {
			avatar: formData.avatar,
			firstname: formData.firstname,
			lastname: formData.lastname,
			prefix: formData.prefix,
			credentials: formData.credentials,
			sector: formData.sector,
			profession: formData.profession,
			gdc: formData.gdc,
			phone: formData.phone,
			skype: formData.skype,
			slack: formData.slack,
			whatsapp: formData.whatsapp,
			telegram: formData.telegram,
			contactEmail: formData.contactEmail,
			threema: formData.threema,
			signal: formData.signal,
		};
	};

	const { formData, updateForm, submitForm, reqLoading, customErrors } = useForms(
		'manageUser',
		formValidationSchema,
		userFieldMap,
		`${config.auth}/v1/user/user.update/${splitUserId(userState?.currUser?.sub)}`,
		'PATCH'
	);

	const handleFormInput = (value, name) => {
		updateForm({ ...formData, [name]: value });
	};

	const handleSubmit = async () => {
		let success = await submitForm(null, `User successfully updated`, (res) => {
			setUser((user) => ({
				...user,
				...formData,
			}));
		});
	};

	async function createImgPath(path) {
		if (path) {
			let imgPath = null;
			try {
				const res = await Axios({
					url: `${config.auth}/file?path=${path}`,
					method: 'GET',
					responseType: 'blob',
				});
				if (res.data) {
					let newPath = window.URL.createObjectURL(new Blob([res.data]));
					imgPath = newPath;
				}
			} catch (err) {
				console.log(err);
				// if (err.response.status === 401) {
				// 	window.location.reload();
				// }
			}
			return imgPath;
		}
	}

	const handleNewAvatar = async (file) => {
		const maxFileSize_OLD = 1000 * 1000 * 20;
		const maxFileSize = 100 * 1000;
		if (!checkUploadFileTypeAllowed(file.type, ['image/png', 'image/jpeg', 'image/jpg'])) {
			return flashFadeModal('Invalid file type. Allowed file types include: JPEG, PNG');
		}
		if (file.size > maxFileSize) {
			return flashFadeModal(`File is too large. Maximum allowed size is ${maxFileSize / 1000 / 1000}MB`);
		}
		let newPath = window.URL.createObjectURL(new Blob([file]));
		let formData = new FormData();
		formData.append('avatar', file);
		formData.append('userUuid', splitUserId(userState?.currUser?.sub));

		const res = await Axios({
			url: `${config.auth}/v1/user/upload.avatar`,
			method: 'PATCH',
			data: formData,
		});

		if (res?.data?.ok === true) {
			let createdAvatar = res?.data?.user?.avatar;
			setAvatarSrc(newPath);
			userDispatch({ type: 'SET_USER_DATA', payload: { avatar: { ...createdAvatar, url: newPath } } });
			return flashFadeModal('Your avatar was successfully updated!');
		} else {
			return flashFadeModal('There was an error when trying to update your avatar. Please contact support');
		}
	};

	useEffect(async () => {
		if (user) {
			setAvatarSrc(await createImgPath(user?.avatar?.path));
		}
	}, [user]);

	return (
		<div className='manageForm thin'>
			<h3 className='marginTop-20 marginBottom-10'>Avatar</h3>
			<div className='section borderBottom'>
				<div className='col'>
					<img src={avatarSrc} width={200} className='avatar' />
					<FileInput name={'file'} btnText={'Upload new'} callback={handleNewAvatar} />
				</div>
			</div>
			<h3 className='marginTop-20 marginBottom-10'>Name</h3>
			<div className='section'>
				<div className='col'>
					<SelectInput
						placeholder='Honorific...'
						htmlOptions={prefixOptions}
						callback={({ value }) => {
							handleFormInput(value, 'prefix');
						}}
						value={formData?.prefix || user?.prefix}
						name={'prefix'}
						required
					/>
					<TextInput
						type='text'
						placeholder='First name...'
						text='First name'
						name='firstname'
						callback={handleFormInput}
						value={formData?.firstname || user?.firstname}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Last name...'
						text='Last name'
						name='lastname'
						callback={handleFormInput}
						value={formData?.lastname || user?.lastname}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Credentials...'
						text='Credentials'
						name='credentials'
						callback={handleFormInput}
						value={formData?.credentials || user?.credentials}
						controlled
					/>
				</div>
			</div>
			<h3 className='marginTop-20 marginBottom-10'>Profession</h3>
			<div className='section'>
				<div className='col'>
					<TextInput
						type='text'
						placeholder='Sector...'
						text='Sector'
						name='sector'
						callback={handleFormInput}
						value={formData?.sector || user?.sector}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Profession...'
						text='Profession'
						name='profession'
						callback={handleFormInput}
						value={formData?.profession || user?.profession}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='GDC Number...'
						text='GDC Number'
						name='gdc'
						callback={handleFormInput}
						value={formData?.gdc || user?.gdc}
						controlled
					/>
				</div>
			</div>
			<h3 className='marginTop-20 marginBottom-10'>Communications</h3>
			<div className='section'>
				<div className='col'>
					<TextInput
						type='text'
						placeholder='Contact email...'
						text='Contact email'
						name='contactEmail'
						callback={handleFormInput}
						value={formData?.contactEmail || user?.contactEmail}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Phone...'
						text='Phone'
						name='phone'
						callback={handleFormInput}
						value={formData?.phone || user?.phone}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Slack...'
						text='Slack'
						name='slack'
						callback={handleFormInput}
						value={formData?.slack || user?.slack}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Skype...'
						text='Skype'
						name='skype'
						callback={handleFormInput}
						value={formData?.skype || user?.skype}
						controlled
					/>
				</div>
				<div className='col'>
					<TextInput
						type='text'
						placeholder='Whatsapp...'
						text='Whatsapp'
						name='whatsapp'
						callback={handleFormInput}
						value={formData?.whatsapp || user?.whatsapp}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Telegram...'
						text='Telegram'
						name='telegram'
						callback={handleFormInput}
						value={formData?.telegram || user?.telegram}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Threema...'
						text='Threema'
						name='threema'
						callback={handleFormInput}
						value={formData?.threema || user?.threema}
						controlled
					/>
					<TextInput
						type='text'
						placeholder='Signal...'
						text='Signal'
						name='signal'
						callback={handleFormInput}
						value={formData?.signal || user?.signal}
						controlled
					/>
				</div>
			</div>
			<div className='btnCont'>
				<Button
					text='Save changes'
					style='primary'
					size='md'
					onClick={() => {
						handleSubmit();
					}}
				/>
			</div>
		</div>
	);
}
